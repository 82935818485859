import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Container, Card, ListGroup, Button, Collapse } from "react-bootstrap";
import GoogleMap from "./GoogleMap";

function AreasCovered() {
  const [open, setOpen] = useState(false);

  const areasCovered = [
    "Medway",
    "Gravesend",
    "Chatham",
    "Gillingham",
    "Rainham",
    "Dartford",
    "Bexley",
    "Maidstone",
    "Sittingbourne",
    "Faversham",
    "Herne Bay",
    "Margate",
    "Ramsgate",
    "Broadstairs",
    "Dover",
    "Folkestone",
    "Hythe",
    "West Malling",
    "East Malling",
    "West Farleigh",
    "East Farleigh",
    "Rochester",
    "Strood",
    "Crayford",
    "Swanscombe",
    "Whitstable",
    "Tonbridge",
    "Tonbridge Wells",
  ];

  const areasCoveredListGroups = [];
  for (let i = 0; i < areasCovered.length; i += 8) {
    areasCoveredListGroups.push(areasCovered.slice(i, i + 8));
  }

  return (
    <Container id="area-covered" className="mb-2">
      <h4>
        Based in the Medway area, providing professional plumbing services to
        the wider Kent area.
      </h4>
      <br />
      <Card>
        <Card.Body className="d-none d-lg-block">
          <GoogleMap width="800" height="600" />
        </Card.Body>
        <Card.Body className="d-none d-md-block d-lg-none">
          <GoogleMap width="600" height="450" />
        </Card.Body>
        <Card.Body className="d-none d-sm-block d-md-none">
          <GoogleMap width="400" height="300" />
        </Card.Body>
        <Card.Body className="d-block d-sm-none">
          <GoogleMap width="250" height="250" />
        </Card.Body>
        <Card.Body>
          <Button
            className="mb-2 w-10"
            variant="dark"
            onClick={() => setOpen(!open)}
            aria-controls="collapsable-text"
            aria-expanded={open}
          >
            Areas Covered
          </Button>
          <Collapse in={open}>
            <div id="collapse-box">
              {areasCoveredListGroups.map((areasCoveredSubarray, index) => (
                <ListGroup
                  horizontal="md"
                  className="d-flex justify-content-center"
                  key={index}
                >
                  {areasCoveredSubarray.map((area) => (
                    <ListGroup.Item variant="success" key={area}>
                      <FaCheck /> {area}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              ))}
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AreasCovered;
