import React, { Component } from "react";
import Slider from "react-slick";
import {
  Container,
  Row,
  Col,
  Card,
  CardGroup,
  ListGroup,
  Button,
} from "react-bootstrap";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2, //change me to 3 when you get more reviews
      slidesToScroll: 2, //change me to 3
      swipeToSlide: true,
      initialSlide: 0,

      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },

        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <h4> See what our customers say about us... </h4>
        <br></br>
        <Slider {...settings}>
          <Card className="mb-2" style={{ width: "100%" }}>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>
                  Andy did an amazing job on our bathroom! From sourcing the
                  exact materials we wanted to finished product. Our bathroom
                  needed completely re-plumbing as we moved the bath and toilet
                  around and nothing was too much of an ask. He also installed
                  our kitchen sink and fitted our washing machine and
                  dishwasher. Would highly recommend!
                </p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">Becky R.</cite>
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
          <Card className="mb-2" style={{ width: "100%" }}>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>
                  Andy arrived quickly for an emergency re. a leaking shower
                  pump. Fixed the issue quickly and efficiently. Wouldn’t
                  hesitate to recommend.
                </p>
                <footer className="blockquote-footer">
                  <cite title="Source Title">Jennifer R.</cite>
                </footer>
              </blockquote>
            </Card.Body>
          </Card>
          <Card className="mb-2" style={{ width: "100%" }}>
            <Card.Body>
              <Button
                href="https://g.page/r/CeSU94YfnT8yEAI/review"
                target="_blank"
                variant="dark"
              >
                Please leave me a review
              </Button>
            </Card.Body>
          </Card>
        </Slider>
      </div>
    );
  }
}
