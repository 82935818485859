import React from "react";
import { Card } from "react-bootstrap";

function ContactForm() {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Send me a message</Card.Title>
        <br></br>
        <form name="contact" method="post">
          <input type="hidden" name="form-name" value="contact" />
          <div className="mb-2">
            <input
              type="text"
              required
              name="name"
              class="form-control"
              id="form-name"
              placeholder="Your Name (required)"
            />
          </div>
          <div className="mb-2">
            <input
              type="email"
              required
              name="email"
              class="form-control"
              placeholder="Email Address (required)"
            />
          </div>
          <div className="mb-2">
            <input
              type="text"
              class="form-control"
              name="phone"
              placeholder="Phone number"
            />
          </div>
          <div className="mb-2">
            <input
              type="text"
              class="form-control"
              name="postcode"
              placeholder="Postcode"
            />
          </div>
          <div className="mb-2">
            <textarea
              name="message"
              placeholder="How can we help?"
              class="form-control"
              rows="10"
            ></textarea>
          </div>

          <button type="submit" value="Submit" class="btn btn-dark">
            Submit
          </button>
        </form>
      </Card.Body>
      <Card.Footer className="text-muted">
        Your data is always safe with us. We will only contact you in
        conjunction with your enquiry.
      </Card.Footer>
    </Card>
  );
}

export default ContactForm;
