import React from "react";

function GoogleMap({ width, height }) {
  return (
    <iframe
      title="Google Map showing area covered by A D Millar Plumbing LTD"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d639954.5471924226!2d0.489427!3d51.2039232!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x660f268e590a4a41%3A0x323f9d1f86f794e4!2sA%20D%20Millar%20Plumbing!5e0!3m2!1sen!2suk!4v1660948611589!5m2!1sen!2suk"
      width={width}
      height={height}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}

export default GoogleMap;
