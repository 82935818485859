import React from "react";
import ContactForm from "../components/ContactForm";
import CallMe from "../components/CallMe";
import EmailMe from "../components/EmailMe";
import { Container } from "react-bootstrap";

function Contact() {
  return (
    <div className="container pb-5 pt-5  col-xs-12">
      <Container className="pb-5">
        <h4>
          Based in the Medway area, providing services across the wider Kent
          area, covering all aspects of plumbing and general building
          maintenance.
        </h4>
      </Container>
      <div className="row d-flex justify-content-center ">
        <div className="col d-flex flex-column pb-5 gap-5 justify-content-evenly">
          <CallMe />
          <EmailMe />
        </div>
        <div className="col-9 order-lg-first d-none d-sm-block">
          <ContactForm />
        </div>
        <div className="d-block d-sm-none">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
