import React from "react";
import { Container } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import CallMeCard from "./CallMeCard";

function Footer() {
  return (
    <div className="bg-dark">
      <Container className="text-light">
        <div className="row">
          <div className="col-sm-4 mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
            <CallMeCard />
          </div>
          <div className="col-sm-4 my-auto mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
            <h6>
              <Link className="text-light" to="/privacypolicy">
                Privacy Policy
              </Link>
            </h6>
            <h6>
              <a
                className="text-decoration-none text-light"
                href="https://www.facebook.com/admillarplumbing"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook /> Facebook
              </a>
            </h6>
            <h6>
              <a
                className="text-decoration-none text-light"
                href="mailto:admillar@zoho.com"
              >
                <div>
                  <MdEmail />
                  admillar@zoho.com
                </div>
              </a>
            </h6>
          </div>
          <div className="order-sm-first col-sm-4 my-auto mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
            <h6>
              Copyright © A D Millar Plumbing LTD 2020 -{" "}
              {new Date().getFullYear()}
            </h6>
            <a
              href="https://find-and-update.company-information.service.gov.uk/company/13037589"
              class="text-light"
              target="_blank"
              rel="noreferrer"
            >
              <h6 alt="CRN 13037589">Company number 13037589</h6>
            </a>
            <h6>Gillingham, Kent, United Kingdom</h6>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
