import React from "react";
import { Card, Container } from "react-bootstrap";
import andyLandscape from "../images/andy-smile.jpg";

function About() {
  return (
    <Container className="mt-5">
      <h2 className="mb-5">About Us</h2>
      <Card className="mb-5">
        <Card.Img variant="top" src={andyLandscape} />
        <Card.Body>
          <Card.Title>Andrew Millar</Card.Title>
          <span className="text-start">
            <h5>Company Overview</h5>
            <p>
              The company is a family-run plumbing business that was established
              during the pandemic in 2020. Despite difficult trading conditions,
              the company has grown and thrived based solely on word of mouth,
              highlighting the importance of customer satisfaction in the
              success of the business. The company offers a full range of
              plumbing services for both domestic and light commercial
              customers, with a focus on delivering complete plumbing solutions
              and prompt, reliable service. The company values integrity,
              reliability, efficiency, and high-quality workmanship, and these
              values guide all aspects of its business operations. The customer
              is always the focal point, and the company strives to ensure that
              all customers are happy with the work performed, no matter how
              small or big the job is. This customer-focused approach is a key
              driving force behind the company's success and continued growth.
            </p>
            <p>
              In addition to its plumbing services, the company also carries a
              wide range of stock and can offer a supply and fit service, or a
              fit only service to meet the needs of its customers. The company's
              goal is to provide a comprehensive plumbing solution, and the team
              of experienced plumbers is dedicated to ensuring that each
              customer receives the best possible service and quality
              workmanship.
            </p>
            <h5>Mission and Values</h5>
            <p>
              The mission of the company is to offer complete plumbing solutions
              for the domestic and light commercial customer. The company values
              prompt and reliable service and aims to provide first-time fixes
              for most reactive works. The company also believes in the
              importance of integrity, reliability, efficiency, and high-quality
              workmanship, and these values guide all aspects of its business
              operations.
            </p>
            <h5>Products and Services</h5>
            <p>
              The company specializes in providing emergency plumbing services
              for leaks and blockages, as well as a full range of plumbing
              maintenance services. In addition to emergency and maintenance
              services, the company also offers the following services:
            </p>
            <ul>
              <li>Taps and toilets</li>
              <li>Installation of hot and cold storage/systems</li>
              <li>Bathroom installations</li>
              <li>Drainage solutions</li>
              <li>Leak detection </li>
              <li>All Water-related services</li>
            </ul>
            <p>
              With the expertise required for all plumbing needs, the company is
              well-equipped to handle any plumbing challenge. Whether it's an
              emergency repair or a new installation, the company has the
              knowledge and skills to provide high-quality workmanship for all
              its services. The team of experienced plumbers is dedicated to
              ensuring that each job is completed to the highest standard and
              that each customer receives the best possible service.
            </p>
          </span>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default About;
