import React from "react";

function PrivacyPolicy() {
  return (
    <div className="text-start p-5">
      <h4>Privacy Policy</h4>
      <p>
        A D Millar Plumbing LTD is committed to protecting the privacy of our
        customers. This Privacy Policy outlines the information that we collect,
        how we use it, and the steps we take to ensure its security.
      </p>
      <h5>Information Collection and Use</h5>
      <p>
        We collect information from our customers in order to provide them with
        the best possible service. This information includes personal
        information such as name, contact information, and payment information.
        This information is used to process transactions, respond to customer
        inquiries, and improve our services.
      </p>
      <h5>Information Sharing and Disclosure</h5>
      <p>
        We do not sell or rent our customers' personal information to third
        parties.
      </p>
      <h5>Security</h5>
      <p>
        We take the security of our customer's personal information very
        seriously. We implement a variety of security measures to maintain the
        safety of personal information, including secure servers and firewalls.
      </p>
      <h5>Data Retention</h5>
      <p>
        We retain personal information only as long as necessary to provide our
        services and fulfill the purposes for which it was collected. After
        this, we will delete or destroy the information in a secure manner.
      </p>
      <h5>Contact Us</h5>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at admillar@zoho.com. We will do our best to respond to your
        inquiry in a timely and helpful manner.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
