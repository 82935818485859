import React from "react";
import logo from "../images/logo.png";
import { TiLocation } from "react-icons/ti";
import CallMe from "./CallMe";
function Header() {
  return (
    <div className="container">
      <div className="row">
        <div className="order-md-1 col-sm-4 my-auto mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
          <a href="#area-covered" class="text-decoration-none text-dark">
            <TiLocation color="#198754" size="30" />
            <h4>Kent, UK</h4>
          </a>
        </div>
        <div className="order-sm-2 order-first col-sm-4 my-auto mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
          <img src={logo} alt="AD Millar Logo" class="card-img" />
        </div>
        <div className="order-sm-3 col-sm-4 my-auto mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
          <CallMe />
        </div>
      </div>
    </div>
  );
}

export default Header;
