import React from "react";

function NotFound() {
  return (
    <div>
      Error 404.<br></br>Page not found
    </div>
  );
}

export default NotFound;
