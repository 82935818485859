import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { FaInfo, FaHome, FaFacebook } from "react-icons/fa";
import { MdPlumbing, MdContactPhone } from "react-icons/md";
import { Link } from "react-router-dom";

function NavbarComponent() {
  const [expanded, setExpanded] = useState(false);

  const handleSelect = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="sm"
      sticky="top"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleSelect}>
          A D Millar Plumbing
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" onClick={handleSelect}>
              <FaHome /> Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={handleSelect}>
              <FaInfo /> About
            </Nav.Link>
            <Nav.Link as={Link} to="/services" onClick={handleSelect}>
              <MdPlumbing /> Services
            </Nav.Link>
            <Nav.Link
              href="https://www.facebook.com/admillarplumbing"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook /> Facebook
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleSelect}>
              <MdContactPhone /> Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
