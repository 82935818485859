import React from "react";
import { Card, Button } from "react-bootstrap";
import { FiPhoneCall } from "react-icons/fi";

function CallMeCard() {
  return (
    <a
      href="tel:07787061212"
      alt="Phone A D Millar"
      className="text-decoration-none"
    >
      <Card className="text-center custom-card">
        <Card.Body>
          <h4 className="text-dark">Need plumbing services right now?</h4>
          <Button
            variant="success"
            href="tel:07787061212"
            size="lg"
            className="mt-3"
            startIcon={<FiPhoneCall />}
          >
            Call me on 07787 061212
          </Button>
        </Card.Body>
      </Card>
    </a>
  );
}

export default CallMeCard;
