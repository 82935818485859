import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import andy from "../images/andy-portrait2.jpg";
import andyLandscape from "../images/andy-smile.jpg";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <>
      <Container className="mt-5 d-none d-lg-block">
        <Row className="d-flex align-items-stretch">
          <Col xs={12} lg={4} style={{ height: "100%" }}>
            <Card className="mb-2 h-100">
              <Card.Img variant="top" src={andy} />
              <Card.Body>
                <Card.Title>Andrew Millar</Card.Title>
                <Card.Text>Professional Plumber</Card.Text>
                <Button as={Link} to="/about" variant="dark">
                  About Me
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={8} className="d-flex flex-column">
            <Card className="mb-2 flex-grow-1">
              <Card.Body>
                <h2>Your Local & Trusted Plumbing Expert</h2>
                <h4>Rapid response plumbing service in Kent</h4>
                <br></br>
                <span className="text-start">
                  <p>
                    We are a local family business, proud to serve our community
                    with high-quality workmanship and exceptional customer
                    service. We understand that plumbing emergencies can happen
                    at any time, which is why we offer 24-hour service.
                  </p>
                  <p>
                    Our expert plumbers are equipped with the latest tools and
                    techniques to ensure that your plumbing issues are resolved
                    quickly and efficiently. In addition to emergency services,
                    we also offer planned, pre-planned and emergency works for
                    your convenience.
                  </p>
                  <p>
                    Whether you need routine maintenance, a minor repair, or a
                    major renovation project, we have the skills and experience
                    necessary to complete the job to the highest standard. We
                    are committed to providing our customers with the best
                    value. You can trust us to provide you with an outstanding
                    service at a fair price.
                  </p>
                  <p>
                    At A D Millar Plumbing LTD, we are dedicated to ensuring
                    that our customers are completely satisfied with our work.
                    We are committed to providing a high-quality service that
                    meets all of your plumbing needs. Contact us today to
                    schedule an appointment and experience the difference that a
                    truly trusted and reliable plumbing service can make.
                  </p>
                </span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5 d-lg-none ">
        <Card>
          <Card.Img variant="top" src={andyLandscape} />
          <Card.Body>
            <h2>Your Local & Trusted Plumbing Expert</h2>
            <h4>Rapid response plumbing service in Kent</h4>
            <br></br>
            <span className="text-start">
              <p>
                We are a local family business, proud to serve our community
                with high-quality workmanship and exceptional customer service.
                We understand that plumbing emergencies can happen at any time,
                which is why we offer 24-hour service.
              </p>
              <p>
                Our expert plumbers are equipped with the latest tools and
                techniques to ensure that your plumbing issues are resolved
                quickly and efficiently. In addition to emergency services, we
                also offer planned, pre-planned and emergency works for your
                convenience.
              </p>
              <p>
                Whether you need routine maintenance, a minor repair, or a major
                renovation project, we have the skills and experience necessary
                to complete the job to the highest standard. We are committed to
                providing our customers with the best value. You can trust us to
                provide you with an outstanding service at a fair price.
              </p>
              <p>
                At A D Millar Plumbing LTD, we are dedicated to ensuring that
                our customers are completely satisfied with our work. We are
                committed to providing a high-quality service that meets all of
                your plumbing needs. Contact us today to schedule an appointment
                and experience the difference that a truly trusted and reliable
                plumbing service can make.
              </p>
            </span>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default HeroSection;
