import React from "react";
import andy from "../images/andy-smile.jpg";
import bathroom from "../images/services/bathroom.jpeg";
import drain from "../images/services/drain.jpeg";
import emergency from "../images/services/emergency.jpeg";
import frozen from "../images/services/frozen.jpeg";
import gutter from "../images/services/gutter.jpeg";
import hot_water_system from "../images/services/hot_water_system.jpeg";
import kitchen from "../images/services/kitchen.jpeg";
import leak from "../images/services/leak.jpeg";
import pump from "../images/services/pump.jpeg";
import radiator from "../images/services/radiator.jpeg";
import water_storage from "../images/services/water_storage.jpeg";

function Services() {
  return (
    <div className="container p-5">
      <h2>Services we offer</h2>
      <br></br>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4 d-flex align-items-center">
            <img src={drain} class="card-img" alt="Blocked drain" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Blockages</h5>
              <p class="card-text text-start">
                Blockages in pipes can cause a range of problems, from slow
                drains to complete blockages that prevent the flow of water.
              </p>
              <p class="card-text text-start">
                Our expert plumbing technicians have the tools and skills to
                quickly and effectively clear blockages in all types of pipes,
                from kitchen sinks to sewer lines.
              </p>
              <p class="card-text text-start">
                We use the latest techniques and equipment to quickly diagnose
                and resolve the issue, ensuring minimal disruption to your daily
                routine.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Leaks</h5>
              <p class="card-text text-start">
                Leaks can cause significant damage to your home or business if
                left untreated.
              </p>
              <p class="card-text text-start">
                Our team of professional plumbers is trained to quickly detect
                and repair leaks of all sizes, from small drips to major pipe
                leaks.
              </p>
              <p class="card-text text-start">
                We use high-quality materials and techniques to ensure a
                long-lasting repair that will keep your property protected.
              </p>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <img src={leak} class="card-img" alt="Leaky tap" />
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4 d-flex align-items-center">
            <img src={frozen} class="card-img" alt="Frozen pipe" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Frozen or Burst Pipes</h5>
              <p class="card-text text-start">
                Frozen or burst pipes can cause extensive damage to your
                property and should be addressed as soon as possible.
              </p>
              <p class="card-text text-start">
                Our team of emergency plumbers is available 24/7 to respond to
                these types of incidents, providing fast and effective solutions
                to prevent further damage.
              </p>
              <p class="card-text text-start">
                We use the latest techniques and equipment to quickly repair or
                replace damaged pipes, ensuring that your property is protected.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Radiators</h5>
              <p class="card-text text-start">
                Radiators are an essential component of your heating system, and
                if they are not working properly, it can cause discomfort and
                inefficiency.
              </p>
              <p class="card-text text-start">
                Our team of experts is trained to repair and maintain all types
                of radiators, from traditional heating systems to modern,
                high-efficiency models.
              </p>
              <p class="card-text text-start">
                We use high-quality materials and techniques to ensure that your
                radiator system is functioning properly and providing the heat
                you need.
              </p>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <img src={radiator} class="card-img" alt="radiator" />
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4 d-flex align-items-center">
            <img src={kitchen} class="card-img" alt="kitchen" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Kitchens</h5>
              <p class="card-text text-start">
                The kitchen is one of the most important rooms in your home, and
                proper plumbing is essential for its function.
              </p>
              <p class="card-text text-start">
                Our team of experts is trained to install and repair all types
                of kitchen plumbing, from sinks and faucets to garbage disposals
                and dishwashers.
              </p>
              <p class="card-text text-start">
                We use the latest techniques and equipment to ensure that your
                kitchen plumbing is working properly and providing the
                convenience and functionality you need.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Bathrooms</h5>
              <p class="card-text text-start">
                Bathrooms are another essential room in your home, and proper
                plumbing is critical for their function.
              </p>
              <p class="card-text text-start">
                Our team of experts is trained to install and repair all types
                of bathroom plumbing, from toilets and showers to sinks and
                faucets.
              </p>
              <p class="card-text text-start">
                We use the latest techniques and equipment to ensure that your
                bathroom plumbing is working properly and providing the comfort
                and convenience you need.
              </p>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <img src={bathroom} class="card-img" alt="bathroom" />
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4 d-flex align-items-center">
            <img src={gutter} class="card-img" alt="gutter" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Guttering and Drainage</h5>
              <p class="card-text text-start">
                Proper guttering and drainage are essential for protecting your
                property from water damage.
              </p>
              <p class="card-text text-start">
                Our team of experts is trained to install and repair all types
                of guttering and drainage systems, ensuring that your property
                is protected from water damage and runoff.
              </p>
              <p class="card-text text-start">
                We use high-quality materials and techniques to ensure that your
                guttering and drainage system is working properly and providing
                the protection you need.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Hot Water Systems</h5>
              <p class="card-text text-start">
                Hot water systems are an essential component of your home or
                business, and proper maintenance and repair are critical for
                their function.
              </p>
              <p class="card-text text-start">
                Our team of experts is trained to install and repair all types
                of hot water systems, from conventional tanks to modern,
                high-efficiency models.
              </p>
              <p class="card-text text-start">
                We use the latest techniques and equipment to ensure that your
                hot water system is working properly and providing the hot water
                you need.
              </p>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <img
              src={hot_water_system}
              class="card-img"
              alt="hot water system"
            />
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4 d-flex align-items-center">
            <img
              src={water_storage}
              class="card-img"
              alt="water storage system"
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Water Storage Systems</h5>
              <p class="card-text text-start">
                Our team of experts is trained to install and repair all types
                of water storage systems, from conventional tanks to modern,
                high-capacity models.
              </p>
              <p class="card-text text-start">
                We use high-quality materials and techniques to ensure that your
                water storage system is working properly and providing the water
                you need.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Pump Solutions</h5>
              <p class="card-text text-start">
                Pumps are critical components of many plumbing systems, and
                proper maintenance and repair are essential for their function.
              </p>
              <p class="card-text text-start">
                Our team of experts is trained to install and repair all types
                of pumps, from sump pumps to water pumps.
              </p>
              <p class="card-text text-start">
                We use the latest techniques and equipment to ensure that your
                pump system is working properly and providing the water pressure
                and flow you need.
              </p>
            </div>
          </div>
          <div class="col-md-4 d-flex align-items-center">
            <img src={pump} class="card-img" alt="pump" />
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="row no-gutters">
          <div class="col-md-4 d-flex align-items-center">
            <img src={emergency} class="card-img" alt="emergency callouts" />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Emergency Callouts</h5>
              <p class="card-text text-start">
                Emergencies can happen at any time, and when it comes to
                plumbing, it's essential to have a reliable and responsive team
                to call on.
              </p>
              <p class="card-text text-start">
                Our team of emergency plumbers is available 24/7 to respond to
                any plumbing emergency, from burst pipes to overflowing toilets.
              </p>
              <p class="card-text text-start">
                We understand the urgency of these situations and will work
                quickly to resolve the issue, minimizing the damage and
                disruption to your daily routine.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
