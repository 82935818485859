import React, { Component } from "react";
import Slider from "react-slick";
import { Card } from "react-bootstrap";

import bathroom from "../images/services/bathroom.jpeg";
import drain from "../images/services/drain.jpeg";
import emergency from "../images/services/emergency.jpeg";
import frozen from "../images/services/frozen.jpeg";
import gutter from "../images/services/gutter.jpeg";
import hot_water_system from "../images/services/hot_water_system.jpeg";
import kitchen from "../images/services/kitchen.jpeg";
import leak from "../images/services/leak.jpeg";
import pump from "../images/services/pump.jpeg";
import radiator from "../images/services/radiator.jpeg";
import water_storage from "../images/services/water_storage.jpeg";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      swipeToSlide: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <h4> Services we offer </h4>
        <br></br>
        <Slider {...settings}>
          <Card>
            <Card.Img variant="top" src={drain} />
            <Card.Body>
              <Card.Title>Blockages</Card.Title>
              <Card.Text>
                Clearing Clogs: Expert Plumbing Solutions for Blocked Pipes
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={leak} />
            <Card.Body>
              <Card.Title>Leaks</Card.Title>
              <Card.Text>
                Stopping the Drip: Professional Solutions for Leaky Pipes
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={frozen} />
            <Card.Body>
              <Card.Title>Frozen or Burst Pipes</Card.Title>
              <Card.Text>
                Thawing the Freeze: Emergency Solutions for Frozen and Burst
                Pipes
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={radiator} />
            <Card.Body>
              <Card.Title>Radiators</Card.Title>
              <Card.Text>
                Warming Up: Expert Repair and Maintenance for Radiator Systems
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={kitchen} />
            <Card.Body>
              <Card.Title>Kitchens</Card.Title>
              <Card.Text>
                Cooking Up Comfort: Professional Kitchen Plumbing Services
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={bathroom} />
            <Card.Body>
              <Card.Title>Bathrooms</Card.Title>
              <Card.Text>
                Bathing in Bliss: Expert Bathroom Plumbing Solutions
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={gutter} />
            <Card.Body>
              <Card.Title>Guttering and Drainage</Card.Title>
              <Card.Text>
                Flowing Smoothly: Professional Solutions for Guttering and
                Drainage Issues
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={hot_water_system} />
            <Card.Body>
              <Card.Title>Hot Water Systems</Card.Title>
              <Card.Text>
                Steaming Solutions: Expert Repair and Maintenance for Hot Water
                Systems
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={water_storage} />
            <Card.Body>
              <Card.Title>Water Storage Systems</Card.Title>
              <Card.Text>
                Storing Sustainably: Professional Solutions for Water Storage
                Systems
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={pump} />
            <Card.Body>
              <Card.Title>Pump Solutions</Card.Title>
              <Card.Text>
                Pumping Power: Expert Solutions for Pump Issues and Maintenance
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={emergency} />
            <Card.Body>
              <Card.Title>Emergency Callouts</Card.Title>
              <Card.Text>
                Urgent Assistance: Rapid Response Emergency Plumbing Callouts
              </Card.Text>
            </Card.Body>
          </Card>
        </Slider>
      </div>
    );
  }
}
