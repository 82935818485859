import React from "react";
import HeroSection from "../components/HeroSection";
import AreasCovered from "../components/AreasCovered";
import ServicesSlider from "../components/ServicesSlider";
import QuoteSlider from "../components/QuoteSlider";

function Home() {
  return (
    <>
      <HeroSection />
      <div className="container p-5">
        <QuoteSlider />
      </div>
      <AreasCovered />{" "}
      <div className="container p-5">
        <ServicesSlider />
      </div>
    </>
  );
}

export default Home;
