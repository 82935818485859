import React from "react";
import { MdEmail } from "react-icons/md";

function EmailMe() {
  return (
    <div>
      <a
        className="text-decoration-none text-dark"
        href="mailto:admillar@zoho.com"
      >
        <h4>
          Email me now at
          <br></br>
          <div className="text-primary">
            <MdEmail /> admillar@zoho.com
          </div>
        </h4>
      </a>
    </div>
  );
}

export default EmailMe;
