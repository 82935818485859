import React from "react";
import { FiPhoneCall } from "react-icons/fi";

function CallMe() {
  return (
    <div>
      <a href="tel: 07787061212" alt="Phone A D Millar" />
      <h4>
        Call me now on
        <br></br>
        <a
          className="link-success text-decoration-none"
          href="tel:07787061212"
          alt="Phone A D Millar"
        >
          <FiPhoneCall />
          07787 061212
        </a>
      </h4>
    </div>
  );
}

export default CallMe;
